import * as URLs from "./api.urls";
import Vue from 'vue'
import { datacontext } from "./datacontext";

export const visitorsService = {
  get,
  checkOut,
  markEntry,
};

async function get(venueId, dateFrom, dateTo, visitorType, timezone) {
  venueId = venueId * 1;

  if (
    venueId < 1 ||
    !dateFrom || dateFrom === "" ||
    !dateTo|| dateTo === ""
  ) {
    return [];
  }
  
  if (visitorType) {
    visitorType = `\\${visitorType}`;
  }

  const params = { timezone };

  return datacontext
    .get(`${URLs.VISITORS_List}\\${venueId}\\${dateFrom}\\${dateTo}${visitorType}`, 
      Vue.prototype.$OAuth.buildHttpHeader(),
      params)
    .then(response => {
      return response && response.visitors ? response.visitors : [];
    });
}

async function checkOut(venueId, eventId) {
  venueId = venueId * 1;

  if(venueId < 1) {
    return;
  }

  return datacontext
  .post(`${URLs.VISITORS_Checkout}\\${venueId}\\${eventId}`, null, Vue.prototype.$OAuth.buildHttpHeader())
  .then(response => {
    return response.data;
  });
}

async function markEntry(venueId, eventId, value) {
  venueId = venueId * 1;

  if(venueId < 1) {
    return;
  }

  if (!value) {
    return;
  }

  return datacontext
  .post(`${URLs.VISITORS_Mark_Entry}\\${venueId}\\${eventId}`,
        {'value': value}, 
        Vue.prototype.$OAuth.buildHttpHeader()
  )
  .then(response => {
    return response.data;
  });
}
