import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { OAuth } from "@nswdoe/doe-ui-core";
import UUID from 'vue-uuid';
  
Vue.use(UUID);
Vue.config.productionTip = false;

// OAuth , all settings below to be provided by your OAuth provider

const authOptions = {
  router: router,
  pkce: true,
  authoriseUri: `${process.env.VUE_APP_OAUTH_SSO_SERVER}/sso/oauth2/authorize`,
  logoutUri: `${process.env.VUE_APP_OAUTH_SSO_SERVER}/sso/logout`,
  secureApp: true,

  forceProdMode: true,
  clientSecret: `${process.env.VUE_APP_OAUTH_CLIENT_SECRET}`,
  params: {
    client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
    redirect_uri: encodeURIComponent(process.env.VUE_APP_OAUTH_REDIRECT_CALLBACK),
    scope: encodeURIComponent(
      "openid visitor"
    )
  }
};
Vue.use(OAuth, authOptions);
// Global event bus
Vue.prototype.$eventHub = new Vue();

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
