<template>
  <div class="evacuation-switch-container">
    <v-switch
        v-model="switchOn"
        label="Evacuation List"
    ></v-switch>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'EvacuationSwitch',
  data: function() {
    return {
      switchOn: false,
    }
  },
  watch: {
    switchOn() {
     if (this.switchOn) {
       this.setEvacuationMode(true);
     } else {
       this.setEvacuationMode(false);
     }
    }
  },
  methods: {
    ...mapActions('visitors', [
      'setEvacuationMode',
    ]),
  }
}
</script>

<style>
.evacuation-switch-container {
  margin-top: 2px;
}

.evacuation-switch-container .v-input {
  margin-top: 0;
}

.evacuation-switch-container .v-input .v-input__control .v-input__slot {
  margin-bottom: 0;
}

.evacuation-switch-container .v-input .v-input__control .v-input__slot .v-label {
  position: initial !important;
  font-size: 14px;
  font-weight: normal;
  color: #121212;
}
</style>