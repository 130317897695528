const axios = require('axios');
const qs = require('qs');

export const oauthService = {
  refreshToken
};

async function refreshToken(refreshToken) {
  const data = qs.stringify({
    'grant_type': 'refresh_token',
    'client_id': process.env.VUE_APP_OAUTH_CLIENT_ID,
    'client_secret': process.env.VUE_APP_OAUTH_CLIENT_SECRET,
    'refresh_token': refreshToken, 
  });
  const config = {
    method: 'post',
    url: process.env.VUE_APP_OAUTH_SSO_SERVER + '/sso/oauth2/access_token',
    headers: { 
      'Content-Type': 'application/x-www-form-urlencoded', 
    },
    data : data
  };

  return await axios(config)
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    throw new Error('Failed to renew token', { cause: JSON.stringify(error) });
  });
}