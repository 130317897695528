<template>
  <v-card
    class="mt-16"
    :class="closingModal ? 'visitor-view-mobile-off' : 'visitor-view-mobile-on'"
  >
    <div class="modal-header">
      <v-btn
        class="my-5 px-0 back-btn"
        elevation="0"
        @click="modalOff"
      >
        <v-icon class="material-icons-outlined my-5">
          mdi-close
        </v-icon>
        <span>Close</span>
      </v-btn>

      <div class="d-flex">
        <div class="playlist-icon-circle ml-3">
          <span class="material-icons-outlined playlist-icon">
          playlist_add_check
          </span>
        </div>
        <div class="pl-5">
          <div class="header-title">School visitor check-in:</div>
          <div class="header-title">{{ record['visitor.name'] }}</div>
        </div>
      </div>
    </div>
    
    <div class="modal-body pt-14">
      <div
        v-for="item in items"
        :key="item.key"
        :class="`item-${item.key}`"
      >
        <div 
          class="item-header" 
          :class="`item-header-${item.key}`"
        >
          {{ item.header }}
        </div>
        <div 
          class="item-value" 
          :class="[`item-value-${item.key}`, item.hasWarning ? 'expired-color' : '']"
          @click="onItemValueClick(item)"
        >
          {{ item.value }}
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import dayjs from 'dayjs';
import { getUTCDateObj } from '../../store/helpers';

export default {
  name: 'VisitorViewMobile',
  props: {
    record: undefined,
  },
  data: function() {
    return {
      closingModal: false,
      items: undefined,
      scrollY: undefined,
      q1Answer: undefined,
    }
  },
  mounted() {
    this.scrollY = window.scrollY;

    // scroll to top
    window.scrollTo(0,0);

    // define headers and get respective values
    this.items = [
      { key: 'firstname', header: 'First name', value: this.record['visitor.name'].split(' ')[0] },
      { key: 'lastname', 
        header: 'Last name', 
        value: this.record['visitor.name'].indexOf(' ') ?
        this.record['visitor.name'].substring(this.record['visitor.name'].indexOf(' '))
        : '',
      },
      { key: 'phone', header: 'Phone no', value: this.record['visitor.phone'] },
      { key: 'checkin', header: 'Check-in',
        value: dayjs(getUTCDateObj(this.record['checkin_timestamp'])).format('DD MMM YYYY hh:mma')
      },
      { key: 'checkout', header: 'Check-out', value: this.getCheckoutTime(), hasWarning: this.getCheckoutWarning() },
      { key: 'type', header: 'Visitor Type', value: this.record['visitor.type']},
      { key: 'orgnaisation', header: 'Organisation', value: this.record['visitor.company_name']},
      { key: 'reason', header: 'Reason for visit', value: this.record['purpose'] },
      // { key: 'Vaccinated', header: 'Vaccinated?', value: this.record['doe_entry_requirements_obj']['value']},
      { key: 'q1', header: 'Will you be working in child-related work?', value: this.q1Answer },
    ];

    // get responses from record
    this.getResponse();
  },
  methods: {
    onItemValueClick(item) {
      // trigger phone call
      if (item.key === 'phone') {
        window.location.href =`tel:+61${item.value.substr(1)}`;
      }
    },
    modalOff() {
      // turn on the slide-out animation
      this.closingModal = true;

      // wait for the animation to finish
      setTimeout(()=>{
        this.setMobileModalOn(false);

        // scroll to previous Y ref
        window.scrollTo(0, this.scrollY);
      }, 150);
    },
    getCheckoutTime() {
      if (!this.record['checkout_timestamp']) {
        return this.record['timeOut'];
      } else {
        return dayjs(getUTCDateObj(this.record['checkout_timestamp'])).format('DD MMM YYYY hh:mma')
      }
    },
    getCheckoutWarning() {
      if (this.record['timeOut'] === 'Expired') {
        return true;
      }

      return false;
    },
    getResponse() {
      if (this.record['additional_details.questions']) {
        for (const elem of this.record['additional_details.questions']) {
          if (elem.description === 'Will you be working in child-related work?') {
            this.q1Answer = elem.response;
          }
        }
      }
    },
    ...mapActions('visitors', [
      'setMobileModalOn',
    ]),
  }
};
</script>

<style scoped>
.visitor-view-mobile-on {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    margin-left: -12px;
    top: 0;
    padding: 0 12px 0 12px;
    animation-name: slide-in;
    animation-duration: .3s;
 }

@keyframes slide-in {
  0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(0);
	}
}

.visitor-view-mobile-off {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  margin-left: -12px;
  top: 0;
  padding: 0 12px 0 12px;
  animation-name: slide-out;
  animation-duration: .3s;
}

@keyframes slide-out {
  0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(100%);
	}
}

.modal-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 11rem;
  background-color: #E5E5E5;
  z-index: -1;
}

.back-btn {
  background-color: transparent !important;
}

.playlist-icon-circle {
  background-color: #C7DCF0;
  width: 4rem;
  height: 4rem;
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  line-height: normal;
  position: relative;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
}

.playlist-icon {
  font-size: 2em;
}

.header-title {
  font-weight: bold;
  font-size: 1.3rem;
}

.item-value {
  font-weight: bold;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.item-header {
  font-size: 0.9rem;
}

.item-value-reason {
 font-weight: inherit;
 margin-bottom: 0.5rem;
}

.item-header-reason {
 margin-top: 0.5rem;
}

.item-reason::before {
  content: '';
  display: block;
  background: silver;
  height: 1px;
  width: 100vw;
  margin-left: -28px;
}

.item-reason::after {
  content: '';
  display: block;
  background: silver;
  height: 1px;
  width: 100vw;
  margin-left: -28px;
  margin-bottom: 1rem;
}

.modal-body {
  padding-left: 1rem;
}

.expired-color {
  color: #DC5800;
}

.item-value-phone {
  text-decoration: underline;
}
</style>