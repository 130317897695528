<template>
  <div class="print flex-container">
    <div class="img-wrapper">
      <img src="/img/nsw-ed-logo-sm.png" alt="icon" class="gov-icon">
    </div>
    
    <div class="visitor-info">
      <h4>Visitor</h4>
      <h5>{{ visitorName }}</h5>
      <small>{{ checkinTime }}</small>
    </div>
  </div>
</template>

<script>
export default {
  name: 'print',
  data: (vm) => ({
    visitorName: vm.$attrs.visitorName,
    checkinTime: vm.$attrs.checkinTime,
    govIcon: null,
  }),
  mounted() {
    this.$nextTick(() => {
      // hide header and banner, fix format
      const header = document.getElementById('appHeader');
      const banner = document.getElementById('appBanner');
      const mobileSchoolMenu = document.getElementById('schoolSelectMobile');
      const mainContainer = document.getElementsByClassName('v-main');

      if (header) {
        header.style.display = 'none';
      }

      if (banner) {
        banner.style.display = 'none';
      }

      if (mobileSchoolMenu) {
        mobileSchoolMenu.style.display = 'none';
      }

      if (mainContainer[0]) {
        mainContainer[0].style.padding = '0px';
      }

      setTimeout(() => {
        window.print();
        window.close();
      }, 500);
    });
  },
};
</script>

<style scoped>
@media print {
  @page {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.flex-container {
  display: flex;
  margin-top: 30px;
}

.gov-icon{
  width: 53px;
  height: 58px;
  margin: 5px 15px 0 15px;
}
</style>